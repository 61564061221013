import styled from 'styled-components'
import { fonts, colors, breakPoints } from '@constants/styles'
import Modal from 'react-modal'

export const DeliverySectionWrapper = styled.div({
  paddingTop: 16,
  textAlign: 'left',
})

export const DeliveryForm = styled.form({
  borderBottom: `solid 1px ${colors.lightGrey}`,
  padding: '0.6em 0.6em 0 0.6em',
  '.professional-delivery-text': {
    '@media screen and (min-width: 768px)': {
      paddingTop: '.625rem',
    },
  },
  h3: {
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '.7rem',
    '@media screen and (max-width: 639px)': {
      fontSize: `${fonts.txtSmall}`,
    },
  },
  h4: {
    marginTop: '0.5em',
    p: {
      display: 'inline',
      padding: '0 0 0 0.25em',
      '&.bold': {
        fontWeight: '600',
      },
    },
  },
  'input[type="text"]': {
    margin: '1.5em 1em 0.5em 1em',
    lineHeight: '20px',
    padding: '10px',
    borderRadius: '5px',
  },
  '.shipping': {
    width: '60px',
    float: 'left',
    padding: '0 5px',
  },
  '.shipping-box': {
    width: '30px',
  },
  '.delivery-radio-container': {
    padding: '0.5rem',
    fontSize: `${fonts.txtSmall}`,
    h4: {
      fontWeight: '600',
      fontSize: `${fonts.txtLarge}`,
    },
    p: {
      fontSize: `${fonts.txtMedium}`,
    },
    '.text-disclaimer': {
      fontSize: `${fonts.txtSmall}`,
    },
    '.radio-btn': {
      maxWidth: '1em',
      minWidth: '1em',
    },
    '.bold': {
      display: 'inline-block',
      fontWeight: '600',
      fontSize: `${fonts.txtSmall}`,
      '&:first-child': {
        marginBottom: '.5em',
      },
      '&.delivery-cost': {
        fontSize: '1.15rem',
        margin: '0.5rem 0',
        '@media screen and (min-width: 768px)': {
          margin: '1rem 0',
        },
      },
    },
    '.change-delivery-btn': {
      color: `${colors.primary}`,
      fontSize: `${fonts.txtSmall}`,
      fontWeight: '600',
      marginTop: '0.25rem',
      textAlign: 'left',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '.help-us': {
      maxWidth: '100%',
      minWidth: '100%',
      maxHeight: '5em',
      minHeight: '5em',
      margin: '0px',
      borderColor: `${colors.lightGrey}`,
      resize: 'none',
    },
    '.char-limit': {
      float: 'right',
      marginRight: '0.1em',
      fontSize: `${fonts.txtSmall}`,
    },
    '.text-label': {
      marginLeft: '0.1em',
      fontSize: `${fonts.txtSmall}`,
    },
  },
  '.delivery-product-container': {
    border: 'none',
    '&.no-border': {
      border: 'none !important',
    },
    '@media screen and (min-width: 1025px)': {
      paddingRight: '1em',
      borderRight: `solid 1px ${colors.lightGrey}`,
    },
  },
  '.date-picker-container': {
    textAlign: 'center',
    '@media screen and (max-width: 639px)': {
      marginLeft: '-0.7em',
    },
    '.date-picker-wrapper': {
      width: '18.92em',
      margin: 'auto',
    },
  },
})

export const DeliveryCondensedWrapper = styled.div`
  text-align: left;
  margin-left: 1em;
  .provided-instructions-container {
    padding: 0.5em 0;
    .provided-instructions {
      display: inline;
      margin-right: 0.4em;
      &.bold {
        font-weight: 600;
      }
    }
  }
  .deliver-section,
  .shipped-section {
    margin-bottom: 1rem;
    margin-right: 5%;
    @media screen and (max-width: ${breakPoints['large-max']}) {
      border-bottom: solid 1px ${colors.lightGrey};
    }
    h3 {
      font-weight: 600;
      text-align: left;
    }
    h4 {
      p,
      div {
        display: inline;
        padding-right: 0.25em;
        @media screen and (max-width: ${breakPoints.small}) {
          font-size: ${fonts.txtSmall};
        }
        &.bold {
          display: inline-block;
          font-weight: 600;
        }
      }
    }
    .distribution-center-button {
      font-size: 18px;
      @media screen and (max-width: ${breakPoints['large-max']}) {
        font-size: 15px;
      }
    }
  }
  .distribution-center-popup {
    padding-right: 0 !important;
  }
`

export const DeliveryProductWrapper = styled.div`
  background: ${colors.primaryInvert};
  width: 100%;
  margin: 0.5rem 0;
  min-height: 2rem;
  &.separator {
    border-bottom: solid 1px ${colors.lightGrey};
  }
  .card {
    display: flex;
    padding: 0.5rem 0;
  }
  .product-container {
    height: auto;
    margin: 0.5rem 0;
    .product-image-container {
      height: auto;
      margin: 0 1em;
      text-align: center;
      .product-image {
        max-width: 3rem;
        max-height: 3rem;
        display: inline-block;
        width: auto;
        height: auto;
        @media screen and (min-width: ${breakPoints['large-min']}) {
          max-width: 8rem;
          max-height: 8rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .product-info {
      a {
        display: block;
        height: 100%;
        font-weight: 400;
        font-size: ${fonts.txtXsmall};
        text-transform: unset;
        color: ${colors.darkGrey};
        margin-left: 0.5em;
        p {
          font-size: ${fonts.txtXsmall};
          &:hover {
            color: ${colors.primary};
            text-decoration: underline;
          }
        }
      }
      .warranty-label {
        display: block;
        margin-left: 1em;
      }
    }
    .quantity {
      text-align: right;
      font-size: ${fonts.txtXsmall};
    }
  }
`

export const DistributionCenterPopupWrapper = styled.div`
  display: inline;
  position: relative;
  .distribution-center-button {
    font-size: 18px;
    color: ${colors.primary};
    font-weight: 400;
    text-align: left;
    @media screen and (max-width: ${breakPoints['large-max']}) {
      font-size: 15px;
    }
    text-decoration: underline dotted;
    text-underline-offset: 2px;
  }
  .popup {
    background: #ffffff;
    position: absolute;
    min-width: 13rem;
    overflow-y: visible;
    border: solid 2px ${colors.lightGrey};
    padding: 10px;
    margin-left: 0;
    top: 1.25rem;
    z-index: 10;
    p {
      display: inline-block !important;
      margin-bottom: 0.25rem;
      font-size: ${fonts.txtSmall};
    }
    a {
      padding-left: 0.25rem;
      color: ${colors.primary};
      font-size: ${fonts.txtSmall};
      &:hover {
        text-decoration: underline;
      }
    }
    &.container {
      width: 100%;
      height: auto;
      margin: 0 auto;
      padding: 0.5rem;
      display: block;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
  }
`

export const PickupList = styled.ul`
  margin-left: 1.2rem;
  padding-left: 0;
  list-style-type: disc;
  line-height: 1rem;
  font-size: ${fonts.txtXsmall};
  @media screen and (min-width: ${breakPoints.medium}) {
    font-size: ${fonts.txtSmall};
    line-height: 1.5rem;
  }
`

export const PickupListHead = styled.p`
  margin: 0.5rem 0;
  font-size: $txt-xsmall;
  @media screen and (min-width: ${breakPoints.medium}) {
    font-size: ${fonts.txtSmall};
  }
`
